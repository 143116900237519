import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import BlogPost from '../components/blog/BlogPost';
import urls from "../constants/urls";
import BackgroundImage from 'gatsby-background-image'

export default function Article({ data, location }) {
  const post = data.article.edges[0].node;
  const otherPosts = data.otherPosts.edges.map(p => p.node);
  const url = `${urls.BASE_URL}${location.pathname}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterShareUrl = `https://twitter.com/share?text=Check out this article:&url=${url}`;

  return (
    <React.Fragment>
      <SEO title={`[Blog Post] - ${post.title}`} description={post.description.description} imageUrl={`https:${post.heroImage.fluid.src}`} url={location.pathname}/>
        <div className="container">
            <article className="page-content page-content--full page-content--light">
              <BackgroundImage Tag="div" className="page-content__heroimg" fluid={post.heroImage.fluid} />
                <div className="page-content__body">
                    <div className="page-content__inner">     
                        <header>
                            <h1>{post.title}</h1>
                            <p className="page-content__intro">{post.description.description}</p>
                            <p className="page-content__date">Written by <strong>{post.author}</strong></p>

                            <div className="page-content__info">
                                <div className="page-content__share">
                                    <p>Share on:</p>
                                    <ul className="list-inline list-topmenu">
                                        <li>
                                            <a href={facebookShareUrl} target="_blank" rel="noreferrer noopener">
                                                <i className="icon-facebook" />
                                            </a>
                                        </li>
                                        &nbsp;
                                        <li>
                                            <a href={twitterShareUrl} target="_blank" rel="noreferrer noopener">
                                                <i className="icon-twitter" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </header>
                        <div dangerouslySetInnerHTML={{__html: post.body.body}} />                            
                    </div>
                </div>
            </article>
            
            <h3 className="section-head">Other Blog posts</h3>
            <div className="blog-posts">
                {otherPosts.map(post =>
                    <BlogPost key={post.slug} post={post} />
                )}  
            </div>  
                </div>
      </React.Fragment>           
  )
}

  export const query = graphql`
    query($slug: String!) {
      article: allContentfulBlogPost(filter: { slug: { eq: $slug } }) {
        edges {
          node {
            title
            slug
            publishDate
            heroImage {
              fluid(maxWidth: 980) {
                ...GatsbyContentfulFluid
              }
              title
            }
            description {
              description
            }
            body {
              body
            }
            author
          }
        }
      }
      otherPosts: allContentfulBlogPost(filter: {slug: {ne: $slug}}) {
        edges {
          node {
            title
            slug
            thumbnailImage {
              fluid(maxWidth: 980) {
                ...GatsbyContentfulFluid
              }
              title
            }
            description {
              description
            }
          }
        }
      }
    }    
  `